.Argument-browser {
  margin-top: 7.5px;
}

.thread-line {
  margin-left: 32px;
  border-left: solid 2px #1e0e5e;
}

.thread-curve {
  position: absolute;
  padding: 14px;
  border: solid 2px;
  border-color: #1e0e5e;
  border-radius: 0 0 0 28px;
  margin-left: -118.5px;
  margin-top: -28px;
  border-right-style: none;
  border-top-style: none;
}

.ArgumentEntry .thread-curve {
  padding: 30px;
  margin-top: -60px;
  margin-left: -118.5px;
}

.thread-point {
  position: absolute;
  padding: 18px;
  border: solid 2px;
  border-color: inherit;
  border-radius: 22px;
  margin-left: -89px;
  background-color: white;
}

.attack:hover .thread-point {
  background-color: rgb(241, 129, 117);
}
.defense:hover .thread-point {
  background-color: rgb(107, 189, 153);
}
.comment:hover .thread-point {
  background-color: rgb(219, 222, 83);
}
.statement:hover .thread-point {
  background-color: #1e0e5e;
  color: white;
}

.Argument.attack {
  border-color: rgb(241, 129, 117);
}
.Argument.attack:hover {
  background-color: rgb(241, 129, 117);
  color: white;
}

.Argument.defense {
  border-color: rgb(107, 189, 153);
}
.Argument.defense:hover {
  background-color: rgb(107, 189, 153);
  color: white;
}

.Argument.comment {
  border-color: rgb(219, 222, 83);
}
.Argument.comment:hover {
  background-color: rgb(219, 222, 83);
  color: white;
}

.Argument.statement {
  border-color: #1e0e5e;
}
.Argument.statement:hover {
  background-color: #1e0e5e;
  color: white;
}

.Argument {
  line-height: 1.2;
  /*  font-weight: lighter; */
  display: flex;
  align-items: center;
  border: solid 2px #1e0e5e;
  border-left: solid 6.5px #1e0e5e;
  margin: 7.5px 8px 7.5px 9px;
  text-align: left;
  padding: 0.35em 1.7em 0.35em;
  border-radius: 23px;
  background: white;
  color: #1e0e5e;
  min-height: 2.5em;
}

.Argument.obsolete {
  color: lightgrey;
}

.Argument .title {
  padding-bottom: 0.14em;
  flex-grow: 1;
}

.Argument-heading {
  display: none;
  /*  display: flex;*/
  align-items: center;
  line-height: 1.3em;
  /*  background-color: #1e0e5e;*/
  /*  color: white; */
  font-weight: bold;
  border: solid 2px black;
  border-left: solid 6.5px black;
  margin: 2px 5px 5px 9px;
  text-align: left;
  padding: 0.55em 1.75em 0.5em;
  border-color: #1e0e5e;
  border-radius: 1px;
  /*  border: none; */
}

.Argument-breadcrumbs .Argument.selected {
  /*
     background: #1e0e5e;
     color: white;
   */
  font-weight: bold;
}

.Argument-breadcrumbs .Argument.selected .chevron {
  visibility: hidden;
}

.Argument-breadcrumbs .Argument {
  margin-right: 8px;
  margin-left: 8px;
}

.Argument-breadcrumbs .title {
  padding-left: 0.3em;
  flex-grow: 1;
}

.Argument-breadcrumbs .chevron {
  left: inherit;
  right: 0.7em;
  transform: rotate(90deg);
}

.Argument-breadcrumbs .chevron:before {
  left: 6px;
}
.Argument-breadcrumbs .chevron:after {
  left: 6px;
}

/*
   .Argument-breadcrumbs .chevron {
   transform: rotate(180deg);
   left: -1.0em;
   }
 */
.chevron {
  position: relative;
  top: 0;
  left: 1.25em;
  text-align: center;
  padding: 7px;
  margin-bottom: 1.5px;
  height: 0.25em;
  width: 0.1em;
  flex-shrink: 0;
}
.chevron:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 51%;
  background: lightgrey;
  transform: skew(45deg, 0deg);
}
.chevron:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 3px;
  height: 50%;
  background: lightgrey;
  transform: skew(-45deg, 0deg);
}
