.App {
  text-align: center;
  color: #1e0e5e;
  font-size: 1.1em;
}

.App-logo {
  display: inline-block;
  height: 100%;
}

.App-header {
  background-color: #1e0e5e;
  color: white;
  height: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.App-header a {
  display: block;
  height: 27%;
}

.App-link {
  color: #09d3ac;
}
