.Argument.ArgumentEntry {
  /*  border-left-width: 2px; */
  margin-bottom: 0px;
}
.Argument.ArgumentEntry:hover {
  background: white;
  color: #1e0e5e;
}
.Argument textarea {
  font: inherit;
  resize: none;
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  padding-bottom: 0.14em;
  margin: 0;
  color: inherit;
  min-width: 0;
}
.Argument button {
  border: 0;
  padding: 0;
  background: inherit;
  color: #1e0e5e;
  position: relative;
  left: 1.1em;
  cursor: pointer;
  flex-shrink: 0;
}
.Argument button[disabled] {
  color: lightgrey;
  cursor: default;
}
.Argument svg {
  fill: currentColor;
}
.ArgumentEntry-submit svg {
  height: 1.3em;
  display: block;
}

.ArgumentEntry-types {
  text-align: right;
  margin: 0px 31px 7.5px 9px;
}
.ArgumentEntry-type {
  display: inline-block;
  width: 3em;
  text-align: center;
  margin-left: 9px;
  border: solid 2px #1e0e5e;
  border-top: none;
  padding: 0;
  background-color: #1e0e5e;
  cursor: pointer;
  font: inherit;
}
.ArgumentEntry-type:hover,
.ArgumentEntry-type.selected {
  background: white;
  border-color: inherit;
  outline: none;
}
.ArgumentEntry-type.attack {
  color: rgb(241, 129, 117);
}
.ArgumentEntry-type.defense {
  color: rgb(107, 189, 153);
}
.ArgumentEntry-type.comment {
  color: rgb(219, 222, 83);
}
.ArgumentEntry-counter {
  position: relative;
  text-align: right;
  right: -28px;
}
.ArgumentEntry-counter.error {
  color: rgb(241, 129, 117);
}
